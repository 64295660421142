import { useStore } from 'vuex';

import types from '../store/types';

const useAuthentication = () => {
  const store = useStore();

  const signIn = (credentials) => store.dispatch(`${types.AUTH_NAMESPACE}/${types.SIGN_IN_ACTION}`, credentials);

  const logout = () => store.dispatch(`${types.AUTH_NAMESPACE}/${types.SIGN_OUT_ACTION}`);

  return {
    signIn,
    logout,
  };
};

export default useAuthentication;
