<template>
  <div id="wrapper" v-if="isConnected">
    <CompaniesRefreshProvider>
      <PWABanner />
      <PAWUpdateApp />
      <Header />
      <div class="page">
        <Menu />
        <MobileNavigation />
        <div v-if="!loading" class="container">
          <slot></slot>
        </div>
        <div v-else class="container">
          <Loader />
        </div>
      </div>
    </CompaniesRefreshProvider>
  </div>
  <div id="wrapper" v-else>
    <template v-if="!loading">
      <slot></slot>
    </template>
    <div v-else class="container">
      <Loader />
    </div>
  </div>
  <div class="safe-area" />
</template>

<script>
import { defineComponent } from 'vue';

import useAuthenticatedUser from '@/composables/useAuthenticatedUser';

import Loader from '@/components/common/Loader.vue';
import PAWUpdateApp from '@/components/pwa/UpdateApp.vue';
import PWABanner from '@/components/pwa/PWABanner.vue';
import Header from '@/components/common/Header.vue';
import Menu from '@/components/common/Menu.vue';
import MobileNavigation from '@/components/common/MobileNavigation.vue';

import CompaniesRefreshProvider from '../../CompaniesRefresh.vue';

export default defineComponent({
  name: 'Layout',
  components: {
    CompaniesRefreshProvider,
    PAWUpdateApp,
    Header,
    Menu,
    MobileNavigation,
    PWABanner,
    Loader,
  },
  props: {
    loading: Boolean,
  },
  setup() {
    const { isConnected } = useAuthenticatedUser();

    return {
      isConnected,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/variables.scss";
@import "@/assets/styles/common/mixins.scss";

.safe-area {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: env(safe-area-inset-bottom);
  background-color: #fff;
}

.container {
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-right: constant(safe-area-inset-right);
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-left: constant(safe-area-inset-left);

  width: 100%;
  padding: 5rem 1.5rem;
  padding: calc(5rem + var(--safe-area-inset-top))
    calc(1.5rem + var(--safe-area-inset-right))
    calc(5rem + var(--safe-area-inset-bottom))
    calc(1.5rem + var(--safe-area-inset-left));

  padding: calc(5rem + env(safe-area-inset-top))
    calc(1.5rem + env(safe-area-inset-right))
    calc(5rem + env(safe-area-inset-bottom))
    calc(1.5rem + env(safe-area-inset-left));

  @include sm {
    padding: 5rem 3rem;
    padding: calc(5rem + var(--safe-area-inset-top))
      calc(3rem + var(--safe-area-inset-right))
      calc(5rem + var(--safe-area-inset-bottom))
      calc(3rem + var(--safe-area-inset-left));

    padding: calc(5rem + env(safe-area-inset-top))
      calc(3rem + env(safe-area-inset-right))
      calc(5rem + env(safe-area-inset-bottom))
      calc(3rem + env(safe-area-inset-left));
  }
}

.page {
  min-height: calc(100vh - 70px);
  display: flex;

  padding-bottom: 8rem;
  @include ipad {
    padding-bottom: 0;
  }
}
</style>
