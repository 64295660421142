<template>
  <header>
    <div>
      <router-link to="/">
        <img class="logo" src="@/assets/images/lowco-pro-logo.svg" alt="Lowco" />
      </router-link>
      <EnterpriseDropdown class="enterprise-dropdown" />
      <span class="link" @click="signOut">Se déconnecter</span>
    </div>
  </header>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import lowcoApi from '@/api/lowco-api';

import EnterpriseDropdown from '../admin/EnterpriseDropdown.vue';
import useCurrentCompany from '../../composables/useCurrentCompany';

export default defineComponent({
  name: 'Header',
  components: {
    EnterpriseDropdown,
  },
  setup() {
    const router = useRouter();

    const { clearCompany } = useCurrentCompany();

    const signOut = async () => {
      try {
        await lowcoApi.logout();
        clearCompany();
      } finally {
        router.push('/signIn');
      }
    };

    return {
      signOut,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

header {
  z-index: 10;
  background-color: $dark-green;
  box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.15);

  .logo {
    width: auto;
    height: 7rem;
  }

  .link {
    cursor: pointer;

    display: none;

    @include ipad {
      display: block;
    }
  }

  a {
    line-height: 0;

    // img {
    //   height: 50px;
    //   margin: 1rem 0;
    //   padding: 0.5rem;
    // }
  }

  span {
    color: $soft-white;
    font-size: 1.8rem;
    cursor: pointer;
  }

  > div {
    padding: 0 5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    @include ipad {
      justify-content: space-between;
    }
  }
}

.enterprise-dropdown {
  display: none;

  @include ipad {
    display: block;
  }
}
</style>
