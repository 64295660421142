import { computed } from 'vue';
import { useStore } from 'vuex';

import types from '../store/types';
import useAuthenticatedUser from './useAuthenticatedUser';

const useCurrentCompany = () => {
  const store = useStore();
  const { profileId, isAdmin } = useAuthenticatedUser();

  const currentCompanyId = computed(() => store.state.company.id);
  const companies = computed(() => store.state.company.all);
  const isFetchingCompanies = computed(() => store.state.company.isLoading);

  const setCurrentCompanyId = (id) => store.commit(`${types.COMPANY_NAMESPACE}/${types.MUTATION_SET_ID}`, id);

  const clearCompany = () => store.commit(`${types.COMPANY_NAMESPACE}/${types.MUTATION_CLEAR}`);

  const fetchAdminCompanies = () => store.dispatch(`${types.COMPANY_NAMESPACE}/${types.ACTION_GET_ALL_ADMIN}`);

  const fetchUserCompanies = () => store.dispatch(`${types.COMPANY_NAMESPACE}/${types.ACTION_GET_ALL_USER}`, profileId.value);

  const fetchCompanies = isAdmin.value
    ? fetchAdminCompanies
    : fetchUserCompanies;

  return {
    currentCompanyId,
    companies,
    isFetchingCompanies,
    fetchCompanies,
    setCurrentCompanyId,
    clearCompany,
  };
};

export default useCurrentCompany;
