<template>
  <div class="modal" v-if="isDisplayed">
    <transition name="fade">
      <div class="content" ref="modal">
        <div v-show="!isFreezed" class="close">
          <FontAwesomeIcon :icon="['fas', 'times']" @click="closeModal" />
        </div>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);

export default {
  name: 'Modal',
  components: {
    FontAwesomeIcon,
  },
  props: {
    isDisplayed: Boolean,
    isFreezed: Boolean,
  },
  watch: {
    isDisplayed(value) {
      document.body.style.overflow = value ? 'hidden' : '';
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    handleOutsideClick(event) {
      if (!this.isDisplayed) {
        return;
      }

      if (this.$refs.modal && !this.$refs.modal.contains(event.target)) {
        this.closeModal();
      }
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  },
  unmounted() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";

.modal {
  z-index: 1000000000000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    text-align: right;
    font-size: 2.5rem;

    > svg {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .content {
    max-height: calc(100vh - 10rem);
    max-width: 128rem;
    overflow-y: auto;
    overscroll-behavior: contain;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: max-height 0.2s ease-out;
}
.fade-enter,
.fade-leave-to {
  max-height: 0;
}
</style>
