<template>
  <slot></slot>
</template>
<script>
import { defineComponent, watch } from 'vue';
import useCurrentCompany from './composables/useCurrentCompany';
import useInterval from './composables/useInterval';

const INTERVAL_TIME_IN_MINUTES = 5;
export default defineComponent({
  name: 'CompaniesRefresh',
  setup() {
    const {
      fetchCompanies,
      companies,
      currentCompanyId,
      setCurrentCompanyId,
    } = useCurrentCompany();
    useInterval(fetchCompanies, INTERVAL_TIME_IN_MINUTES * 60000, true);

    const unwatch = watch(companies, (value) => {
      if (!value?.length) {
        return;
      }

      if (currentCompanyId.value) {
        unwatch();
        return;
      }

      const [firstCompany] = value;
      setCurrentCompanyId(firstCompany.id);

      unwatch();
    });
  },
});
</script>
