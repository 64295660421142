<template>
  <nav class="menu" :style="{ top: menuTop }">
    <ul>
      <li>
        <router-link to="/home">
          <FontAwesomeIcon :icon="faHome" />
          <span>{{ t('components.menu.home') }}</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profile">
          <FontAwesomeIcon :icon="faUser" />
          <span>{{ t('components.menu.profile') }}</span>
        </router-link>
      </li>
      <li>
        <router-link to="/catalog">
          <FontAwesomeIcon :icon="faBookOpen" />
          <span>{{ t('components.menu.catalog') }}</span>
        </router-link>
      </li>
      <li>
        <router-link :to="routesConstants.OFFERS">
          <FontAwesomeIcon :icon="faBadgePercent" />
          <span>{{ t('components.menu.offers') }}</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profile/new-enterprise">
          <FontAwesomeIcon :icon="faPlusCircle" />
          <span>{{ t('components.menu.addEnterprise') }}</span>
        </router-link>
      </li>
      <li v-if="isAdmin">
        <router-link to="/admin/enterprises">
          <FontAwesomeIcon :icon="faStore" />
          <span>{{ t('components.menu.enterprises') }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faBookOpen,
  faStore,
  faPlusCircle,
  faBadgePercent,
} from '@fortawesome/pro-solid-svg-icons';

import useAuthenticatedUser from '@/composables/useAuthenticatedUser';

import routesConstants from '@/constants/routes.constants';

export default {
  name: 'Menu',
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const { t } = useI18n();
    const { isAdmin } = useAuthenticatedUser();

    return {
      t,
      isAdmin,
      routesConstants,
      faHome,
      faUser,
      faBookOpen,
      faStore,
      faPlusCircle,
      faBadgePercent,
    };
  },
  data() {
    return {
      menuTop: '70px',
    };
  },
  props: {
    hasAlert: Boolean,
  },
  mounted() {
    window.addEventListener('scroll', this.menuTopScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.menuTopScroll);
  },
  watch: {
    enterprise() {
      this.menuTopScroll();
    },
  },
  methods: {
    menuTopScroll() {
      let headerHeight = 70;
      const alertHeight = 46;

      if (this.enterprise && !this.enterprise.mollieCustomerId) {
        headerHeight += alertHeight;
      }

      let menuTopValue = headerHeight - window.pageYOffset;

      if (menuTopValue < 0) {
        menuTopValue = 0;
      }

      this.menuTop = `${menuTopValue}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.menu {
  width: 100%;
  max-width: 230px;
  z-index: 0;
  background-color: $dark-white;

  display: none;

  @include ipad {
    display: block;
  }

  ul {
    margin-top: 2rem;
    li {
      a {
        color: $dark-green;
        font-size: 2rem;
        padding: 1rem;
        padding-left: 2.3rem;
        width: 100%;

        &:hover {
          color: $main-color;
        }

        svg {
          width: 2rem !important;
          font-size: 1.8rem;
          margin-right: 1rem;
        }

        &.router-link-active {
          color: $main-color;
        }
      }
    }
  }
}
</style>
