<template>
  <div id="pwa-installation">
    <!-- <img
      src="@/assets/images/icon.png"
      alt="Icone Lowco"
    /> -->
    <span
      v-on:click="installPWA()"
      class="pointer"
    >Télécharger l'application</span>
  </div>
</template>

<script>
export default {
  name: 'PWAInstallation',
  methods: {
    installPWA() {
      this.$emit('install-pwa');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/variables.scss";

div {
  color: $soft-white;
  background-color: $dark-green;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  img {
    margin: 5px;
    width: 50px;
    height: 50px;
  }

  span {
    text-decoration: underline;
  }
}
</style>
