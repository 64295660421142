<template>
  <BottomNavigation
    class="bottom-menu"
    :options="navMenuItems"
    :value="currentTab"
    foregroundColor="#ddefe2"
    badgeColor="#02474d"
    @update="handleNavigationChange"
  >
    <template v-slot:icon="{ props }">
      <FontAwesomeIcon :icon="props.icon" />
    </template>
  </BottomNavigation>

  <div :class="{ 'drawer': true, 'drawer--is-open': currentTab === MORE_TAB }">
    <nav class="drawer__nav">
      <div class="nav__top">
        <EnterpriseDropdown />
      </div>

      <ul class="nav__list">
        <li class="nav__item">
          <router-link :to="routesConstants.HOME" v-slot="{ isActive }" @click="handleLinkClick">
            <span :class="{ 'active': isActive }">
              <FontAwesomeIcon class="mr-2" :icon="faHome" />
              {{ t('components.menu.home') }}
            </span>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link :to="routesConstants.PROFILE" v-slot="{ isActive }" @click="handleLinkClick">
            <span :class="{ 'active': isActive }">
              <FontAwesomeIcon class="mr-2" :icon="faUser" />
              {{ t('components.menu.profile') }}
            </span>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link :to="routesConstants.CATALOG" v-slot="{ isActive }" @click="handleLinkClick">
            <span :class="{ 'active': isActive }">
              <FontAwesomeIcon class="mr-2" :icon="faBookOpen" />
              {{ t('components.menu.catalog') }}
            </span>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link :to="routesConstants.OFFERS" v-slot="{ isActive }" @click="handleLinkClick">
            <span :class="{ 'active': isActive }">
              <FontAwesomeIcon class="mr-2" :icon="faBadgePercent" />
              {{ t('components.menu.offers') }}
            </span>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link
            :to="routesConstants.NEW_ENTERPRISE"
            v-slot="{ isActive }"
            @click="handleLinkClick"
          >
            <span :class="{ 'active': isActive }">
              <FontAwesomeIcon class="mr-2" :icon="faPlusCircle" />
              {{ t('components.menu.addEnterprise') }}
            </span>
          </router-link>
        </li>
        <li class="nav__item" v-if="isAdmin">
          <router-link
            :to="routesConstants.ADMIN_ENTERPRISES"
            v-slot="{ isActive }"
            @click="handleLinkClick"
          >
            <span :class="{ 'active': isActive }">
              <FontAwesomeIcon class="mr-2" :icon="faStore" />
              {{ t('components.menu.enterprises') }}
            </span>
          </router-link>
        </li>
      </ul>

      <div class="nav__bottom">
        <span class="link" @click="signOut">{{ t('components.menu.signOut') }}</span>
      </div>
    </nav>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  watchEffect,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faBookOpen,
  faEllipsisH,
  faPlusCircle,
  faStore,
  faBadgePercent,
} from '@fortawesome/pro-solid-svg-icons';

import useAuthentication from '@/composables/useAuthentication';
import useAuthenticatedUser from '@/composables/useAuthenticatedUser';
import useCurrentCompany from '@/composables/useCurrentCompany';

import routesConstants from '@/constants/routes.constants';

import BottomNavigation from './BottomNavigation.vue';
import EnterpriseDropdown from '../admin/EnterpriseDropdown.vue';

const MORE_TAB = 'more';

export default defineComponent({
  name: 'MobileNavigation',
  components: { FontAwesomeIcon, BottomNavigation, EnterpriseDropdown },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { t } = useI18n();

    const { logout } = useAuthentication();
    const { isAdmin } = useAuthenticatedUser();
    const { clearCompany } = useCurrentCompany();

    const currentTab = ref(route.path);

    const navMenuItems = [
      {
        id: routesConstants.HOME,
        icon: faHome,
        title: t('components.menu.home'),
      },
      {
        id: routesConstants.PROFILE,
        icon: faUser,
        title: t('components.menu.profile'),
      },
      {
        id: routesConstants.CATALOG,
        icon: faBookOpen,
        title: t('components.menu.catalog'),
      },
      {
        id: MORE_TAB,
        icon: faEllipsisH,
        title: t('components.menu.more'),
      },
    ];

    watchEffect(() => {
      document.body.style.overflow = currentTab.value === MORE_TAB ? 'hidden' : '';
    });

    watchEffect(() => {
      if (route.path === '/') {
        return;
      }

      currentTab.value = route.path;
    });

    const handleNavigationChange = (value) => {
      currentTab.value = value;

      if (value !== MORE_TAB) {
        router.push(value);
      }
    };

    const handleLinkClick = (event) => {
      const { pathname: newRoute } = event.view.location;

      if (newRoute === route.path) {
        currentTab.value = newRoute;
      }
    };

    const signOut = async () => {
      try {
        await logout();
        clearCompany();
      } finally {
        router.push(routesConstants.SIGN_IN);
      }
    };

    return {
      t,
      routesConstants,
      currentTab,
      navMenuItems,
      handleNavigationChange,
      signOut,
      isAdmin,
      handleLinkClick,
      MORE_TAB,
      faHome,
      faUser,
      faBookOpen,
      faPlusCircle,
      faStore,
      faBadgePercent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.bottom-menu {
  @include ipad {
    display: none;
  }
}

.drawer {
  position: fixed;
  right: 0;
  top: 0;
  height: calc(100vh - 6rem - env(safe-area-inset-bottom));
  width: 100%;
  transition: all 0.3s ease;
  transform: translateX(100%);
  background-color: #fff;
  z-index: 999999;

  &--is-open {
    transform: translateX(0);
  }

  &__nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 8rem 4rem;
  }

  @include ipad {
    display: none;
  }
}

.nav {
  &__top {
    display: flex;
    justify-content: center;
    align-items: center;

    .lowco-textbox {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    font-size: 2rem;

    a {
      font-size: inherit;
    }

    .active {
      color: $main-color;
    }
  }

  &__item + &__item {
    margin-top: 2.5rem;
  }
}
</style>
